@use "sass:color";

@import "variables";
@import "bootstrap/scss/bootstrap";
@import "main";

#bottom-btn {
	display: flex;
	position: fixed;
	z-index: 1000;
	right: 2rem;
	bottom: 4rem;
	font-size: 0.7rem;
	cursor: pointer;
	transition: all,0.2s;
	visibility:hidden;
	align-items: baseline;
	opacity: 0;

	svg {
		fill: lighten($black, 70%);
		transition: all ease-in-out 0.2s;
		background:white;
		border-radius: 5px;
		box-shadow: 0px,3px,5px,0px,rgba(0,0,0,.1);
		opacity: 0.9;
		height: 2.4rem;
		width: 2.4rem;
		&:hover {
			fill: lighten($black, 80%);
		}
		@media(min-width: 992px){
			background:none;
			border-radius: 0;
			box-shadow: 0,0,0,0,white;
			opacity: 1;
		}
	}

	&.showIt {
		visibility: visible;
		opacity: 1;
	}
}

.footer {
	color: transparentize($black,0.15);	

	h1 {
		font-size: 1.2rem;
		margin:0.5rem 0 !important;	
		font-weight: 800 !important;
	}

	@include media-breakpoint-up (sm) {
		h1 {@include font-size($h1-font-size*0.4);}
    }	

	img {
		max-width: 4rem;
	}

	h2 {
		@include font-size($h2-font-size*0.4);
		color: transparentize($blueindie,0.15);
		margin: 0 0 0.5rem 0 !important;
	}

	li {
		line-height: 0.9;
		margin-bottom: 0.5rem;

		a {
		color: transparentize($black,0.2);
		@include font-size(1rem*0.8);
		&:hover{
			color: $black;
			text-decoration: none;
			}
		}
	}

	.compagnon {
		@include font-size($font-size-base*0.7);
	}
}

// style subscription box in footer

.ml-sub {
    input {
        border:1px solid $blueindie;
        border-radius: 0.25rem 0 0 0.25rem;
    }
    button {
        border-radius: 0 0.25rem 0.25rem 0;
    }
}

#form-merci, #form-oups, #form-merci:target ~ form, #form-oups:target ~ form {
    display: none;
}

#form-merci:target, #form-oups:target {
    display: block;
}
